import Imm from 'immutable';
import React from 'react';
import AddReworkTimeController from './AddReworkTimeController';
import AssetChartController from './AssetChartController';
import AssetStampBandController from './AssetStampBandController';
import AssetScrapSignatureController from './AssetScrapSignatureController';
import BaseController from '../../lib/BaseController';
import InspectionNotPerformedController from './InspectionNotPerformedController';
import EditAssetCertificateController from './EditAssetCertificateController';
import EditThirdPartyJobNumberController from './EditThirdPartyJobNumberController';
import RecordInspectionTimeController from './RecordInspectionTimeController';
import InspectionPassedController from './InspectionPassedController';
import InspectionFailedController from './InspectionFailedController';
import Dialog from '../../components/Dialog';
import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import LoginService from '../services/LoginService';
import messages from '../../services/Messages';
import SAPService from '../services/SAPService';
import ServiceLevelHelper from '../services/ServiceLevelHelper';
import co from '../../lib/Co';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import InspectionTestIcons from '../utility/InspectionTestIcons';
import AppConstants from '../utility/AppConstants';
import SecurityRights from '../utility/SecurityRights';
import Utils from '../utility/Utils';
import AddReworkTimeDialog from '../pages/AddReworkTimeDialog.js';
import AssetChartDialog from '../dialogs/AssetChartDialog';
import AssetStampBandDialog from '../pages/AssetStampBandDialog.js';
import AssetScrapSignatureDialog from '../pages/AssetScrapSignatureDialog.js';
import InspectionNotPerformedDialog from '../pages/InspectionNotPerformedDialog.js';
import EditAssetCertificateDialog from '../pages/EditAssetCertificateDialog.js';
import EditThirdPartyJobNumberDialog from '../pages/EditThirdPartyJobNumberDialog.js';
import RecordInspectionTimeDialog from '../pages/RecordInspectionTimeDialog.js';
import InspectionPassedDialog from '../pages/InspectionPassedDialog.js';
import InspectionGaugesDialog from '../pages/InspectionGaugesDialog.js';
import InspectionGaugesController from './InspectionGaugesController.js';
import InspectionFailedDialog from '../pages/InspectionFailedDialog.js';
import TorqueValuesController from '../controllers/TorqueValuesController.js';
import TorqueValuesDialog from '../pages/TorqueValuesDialog.js';
import DimensionValuesController from '../controllers/DimensionValuesController.js';
import DimensionValuesDialog from '../pages/DimensionValuesDialog.js';
import InspectionTestNotesController from './InspectionTestNotesController.js';
import InspectionTestGaugesController from './InspectionTestGaugesController.js';
import InspectionTestNotesDialog from '../pages/InspectionTestNotesDialog.js';
import InspectionTestGaugesDialog from '../pages/InspectionTestGaugesDialog.js';
import ComponentUpdateDialog from '../pages/ComponentUpdateDialog.js';
import CustomerDocumentsDialog from '../dialogs/CustomerDocumentsDialog.js';
import ComponentUpdateController from './ComponentUpdateController.js';
import ThicknessReadingsDialog from '../pages/ThicknessReadingsDialog.js';
import ThicknessReadingsController from '../controllers/ThicknessReadingsController.js';
import ReadingsConfirmationDialog from '../pages/ReadingsConfirmationDialog.js';
import ReadingsConfirmationController from '../controllers/ReadingsConfirmationController.js';
import SerialReadingHeaderRowController from '../controllers/SerialReadingHeaderRowController.js';
import $ from 'jquery';
import lodash from 'lodash';
import FileNameRowController from '../controllers/FileNameRowController';
import emitter from '../utility/Emitter';
import ManualChartUpload from '../dialogs/ManualChartUploadDailog.js';
import ManualChartUploadController from './ManualChartUploadController.js';


class InspectionController extends BaseController {
  constructor() {
    super('inspection', {
      selectedJob: '',
      inspectionJob: { assets: [] },
      showSummary: false,
      activeTestName: '',
      groups: [],
      inspectionTests: [
        { name: 'Visual Inspection', className: 'visual', enabled: false },
        { name: 'Connection Prep', className: 'connectionprep', enabled: false },
        { name: 'Mag Particle Inspection (MPI)', className: 'mpi', enabled: false },
        { name: 'Thickness (UT)', className: 'thickness', enabled: false },
        { name: 'Gauge', className: 'gauge', enabled: false },
        { name: 'Teardown', className: 'teardown', enabled: false },
        { name: 'Rebuild', className: 'rebuild', enabled: false },
        { name: 'Reassemble', className: 'rebuild', enabled: false },
        { name: 'Grease', className: 'grease', enabled: false },
        { name: 'Pressure Test', className: 'pressuretest', enabled: false },
        { name: 'Teardown (Optional)', className: 'teardownopt', enabled: false },
        { name: 'Rebuild (Optional)', className: 'rebuildopt', enabled: false },
        { name: 'Pressure Test (Optional)', className: 'pressuretestopt', enabled: false },
        { name: 'Stamp/Band', className: 'stampband', enabled: false },
        { name: 'Paint', className: 'paint', enabled: false },
        { name: 'Final Inspection', className: 'finalinspection', enabled: false }
      ],
      extraTests: [],
      inspectionMinutes: 0,
      isAddTorqueValueDisable: true,
      isAddDimensionValueDisable: true,
      istecountecoDisable: false,
      componentvals: [],
      multiSelectGroups: '',
      untecoInProgress: false,
      serialWithReadings: [],
      serialWithoutReadings: [],
      serialWithReadingsHeader: [],
      serialWithoutReadingsHeader: [],
      IsInProgress: false,
      isSaveInProgress: false,
      assetsMarkedDownloadHeader: [],
      assetsMarkedDownload: [],
      activeTab: 1,
      jobChanged: false
    });


    const self = this;
    this.flash = emitter();

    messages.channel('change-facility').action('changed').subscribe((facility) => {
      this.state.selectedJob = '';
      this.loadAssetsForJob(null);
    });

    messages.channel('reload-asset-readings').action('changed').subscribe(() => {
      this.getReadingDetailsForSerial(true);
    });

    messages.channel('edit-3rd-party-job-number').action('saved').subscribe(data => {
      if (!data || !data.jobId) {
        return;
      }
      let job = this.state.selectedJob;
      if (!job || (data.jobId !== job.id)) {
        return;
      }
      job.thirdPartyJobNumber = data.thirdPartyJobNumber;
      const message = data.thirdPartyJobNumber
        ? 'Updated 3rd party job number to ' + data.thirdPartyJobNumber + ' for job # ' + job.jobNumber
        : 'Cleared 3rd party job number for job # ' + job.jobNumber;
      notification.action('success').post(message);
      this.commit();
    });

    messages.channel('scrap-asset').action('saved').subscribe(() => {
      let selectedAssetIds = [];
      if (this.state.inspectionJob && this.state.inspectionJob.assets) {
        selectedAssetIds = this.getSelectedAssets().map(a => a.jobSOWId);
      }
      this.loadAssetsForJob(this.state.selectedJob.id, selectedAssetIds);
    });

    messages.channel('assign-service-levels').action('saved').subscribe(updatedJob => {
      const job = this.state.selectedJob;
      if (!job || !job.id || (updatedJob.jobId !== job.id)) {
        return;
      }

      // current job just had service levels updated - reload
      this.loadAssetsForJob(job.id);
    });

    messages.channel('edit-asset-certificate').action('saved').subscribe(data => {
      const job = this.state.inspectionJob;
      if (!job || !job.jobId || (job.jobId !== data.jobId)) {
        return;
      }
      var message = data.certificate
        ? 'Updated certificate to ' + data.certificate + ' for ' + Utils.pluralize('asset', 's', data.assetCount) + ' on job # ' + data.jobNumber
        : 'Cleared certificate for ' + Utils.pluralize('asset', 's', data.assetCount) + ' on job # ' + data.jobNumber;
      notification.action('success').post(message);

      const assetIds = this.getSelectedAssets().map(a => a.jobSOWId);
      this.loadAssetsForJob(this.state.selectedJob.id, assetIds);
    });

    messages.channel('inspection').action('saved').subscribe(data => {
      const job = this.state.inspectionJob;
      if (!job || !job.jobId || (job.jobId !== data.jobId)) {
        return;
      }
      const assetIds = this.getSelectedAssets();
      //this.loadAssetsForJob(this.state.selectedJob.id, assetIds);
      this.loadUpdatedAssetsForJob(this.state.selectedJob.id, assetIds);
    });

    messages.channel('edit-asset').action('saved').subscribe(savedAsset => {
      const assetsToUpdate = this.state.inspectionJob.assets.filter(asset => asset.jobSOWId === savedAsset.jobSOWId);
      if (assetsToUpdate.length !== 1) {
        return;
      }

      let assetToUpdate = assetsToUpdate[0];
      assetToUpdate.primarySerialNumber = savedAsset.primarySerialNumber;
      assetToUpdate.secondarySerialNumber = savedAsset.secondarySerialNumber;
      this.commit();
    });

    messages.channel('login').action('loggedin').subscribe(data => {
      if (!data) {
        this.clearResults();
      }
    });

    messages.channel('reload-test-results').action('saved').subscribe(() => {
      this.loadTestResults(this.state.selectedJob.id, this.getSelectedAssets());
    });

    messages.channel('job-status').action('updated').subscribe(data => {
      const job = this.state.selectedJob;
      if (!job || (job.id !== data.jobId)) {
        return;
      }

      if (data.message && data.message.action) {
        notification.action(data.message.action).post(data.message);
      }

      this.loadJob(job.id);
    });

    messages.channel('lock-for-inspection').action('updated').subscribe(result => {
      if (result.jobSOWId) {
        this.updateAsset(result, 'lock-for-inspection');
      }
    });
  }

  updateAsset = (result, channel) => {
    if (!result || !result.jobSOWId || !this.state.inspectionJob || !this.state.inspectionJob.assets) {
      return;
    }

    let asset = this.state.inspectionJob.assets.find(a => a.jobSOWId === result.jobSOWId);
    if (!asset) {
      return;
    }

    if (result.sapErrorCode !== undefined) {
      asset.sapErrorCode = result.sapErrorCode;
    }
    if (result.sapErrorMessage !== undefined) {
      asset.sapErrorMessage = result.sapErrorMessage;
    }
    if (result.serviceOrderNumber !== undefined) {
      asset.serviceOrderNumber = result.serviceOrderNumber;
    }
    if (result.sapStatus !== undefined) {
      asset.sapStatus = result.sapStatus;
    }
    if (result.reason !== undefined) {
      asset.waitingForReason = result.reason;

      switch (channel) {
        case 'reapply-service-levels':
          asset.isWaitingForReapplyServiceLevels = (result.reason === AppConstants.AssetWaitingReason.ReapplyServiceLevels);
          break;

        case 'lock-for-inspection':
          asset.isWaitingForLockForInspection = (result.reason === AppConstants.AssetWaitingReason.LockForInspection);
          break;

        default:
          break;
      }
    }
    this.commit();
  }

  clearResults = () => {
    this.state.selectedJob = '';
    this.state.inspectionJob = { assets: [] };
  }

  clearUTScopeResults = () => {
    this.state.serialWithReadings = [];
    this.state.serialWithoutReadings = [];
    this.state.serialWithReadingsHeader = [];
    this.state.serialWithoutReadingsHeader = [];
    this.state.assetsMarkedDownloadHeader = [];
    this.state.assetsMarkedDownload = [];
    this.commit();
  }

  toggleJobSummary = () => {
    this.state.showSummary = !this.state.showSummary;
    this.commit();
  }

  selectedJobChanged = () => {
    this.state.jobChanged = true;
    if (this.state.selectedJob) {
      if (this.state.activeTab == 2 && this.state.selectedJob) {
        this.loadJobDetails(this.state.selectedJob.id);
        this.configureTestButtons();
      }
      else {
        this.loadJob(this.state.selectedJob && this.state.selectedJob.id);
        this.configureTestButtons();
      }
    }
    else {
      this.clearUTScopeResults();
      this.clearResults();
    }
  }

  clearGroupSelection = () => {
    this.state.multiSelectGroups = '';
    $('#group-multiselect').select2('data', []);
    this.state.groups.forEach(group => group.selected = false);
    this.deselectAssetsInUnselectedGroups();
    this.commit();
  }

  deselectAssetsInUnselectedGroups = () => {
    const unselectedGroups = this.state.groups.filter(group => !group.selected);
    if (unselectedGroups.length === this.state.groups.length) {
      // all groups are unselected - no need to deselect assets
      return;
    }

    this.state.inspectionJob.assets.forEach(asset => {
      for (let i = 0; i < unselectedGroups.length; ++i) {
        const group = unselectedGroups[i];
        if (asset.groupNumber === group.value) {
          asset.selected = false;
        }
      }
    });
  }

  toggleGroupSelection = (clickedGroup) => {
    this.state.groups.forEach(group => {
      if (group.value.groupNumber === parseInt(clickedGroup, 10)) {
        group.selected = !group.selected;
      }
    });
    this.deselectAssetsInUnselectedGroups();
    this.configureTestButtons();
    this.commit();
  }

  multiSelectGroupsChanged = () => {
    if (this.state.multiSelectGroups) {
      let selectedGroups = this.state.multiSelectGroups.map(group => group.id);
      this.state.groups.forEach(group => {
        if (selectedGroups.includes(group.value.groupNumber)) {
          group.selected = true;
        }
        else {
          group.selected = false;
        }
      });
    }
    else {
      this.state.groups.forEach(group => {
        group.selected = false;
      });
    }
    this.deselectAssetsInUnselectedGroups();
    this.configureTestButtons();
    this.commit();
  }

  loadJobDetails = (jobId) => {
    this.state.selectedJob = '';
    this.state.inspectionJob = { assets: [] };
    this.state.groups = [];
    this.configureTestButtons();
    this.commit();
    if (jobId) {
      ajax.get('lookup/getJob', { jobId: jobId }).then(results => {
        this.state.selectedJob = results;
        this.commit();
        this.getReadingDetailsForSerial();
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });   // JobLookupDto
    }
  }


  loadJob = (jobId, loadAsset = true) => {
    this.state.selectedJob = '';
    this.state.inspectionJob = { assets: [] };
    this.state.groups = [];
    this.configureTestButtons();
    this.commit();

    if (jobId) {
      ajax.get('lookup/getJob', { jobId: jobId }).then(results => {
        this.state.selectedJob = results;
        //this.loadAssetsForJob(jobId);
        this.commit();
        if (loadAsset) {
          const tab = '1';
          this.tabShow(tab);
        }
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });  // JobLookupDto
    }
  }

  hasJobChanged = (jobId) => {
    if (!jobId) {
      return true;
    }

    if (!this.state.selectedJob) {
      return true;
    }

    return (this.state.selectedJob.id !== jobId);
  }

  loadAssetsForJob = (jobId, selectedAssets) => {
    this.state.activeTestName = '';
    if (this.hasJobChanged(jobId)) {
      this.state.groups = [];
      this.commit();
    }
    this.state.inspectionJob = { assets: [] };
    if (jobId) {
      this.isLoading = true;
      this.commit();
      ajax.get('lookup/getInspectionAssets', { jobId: jobId }).then(results => {
        this.state.inspectionJob = results;
        this.state.jobChanged = false;
        this.initializeGroups();
        if (selectedAssets && selectedAssets.length) {
          results.assets.forEach(a => {
            for (var i = 0; i < selectedAssets.length; ++i) {
              if (selectedAssets[i] === a.jobSOWId) {
                a.selected = true;
              }
            }
          });
        }
        // else
        // {
        //   var selAssets=[];
        //   if (this.state.inspectionJob && this.state.inspectionJob.assets) {
        //     selAssets = this.getSelectedAssets().map(a => a.assetId);
        //   }
        //   if (selAssets && selAssets.length) {
        //     results.assets.forEach(a => {
        //       for (var i = 0; i < selAssets.length; ++i) {
        //         if (selAssets[i] === a.assetId) {
        //           a.selected = true;
        //         }
        //       }
        //     });
        //   }
        // }
        this.state.inspectionJob = results;
        this.isLoading = false;
        this.commit();
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });
    }
  }

  groupSearch = () => {
    const self = this;
    return function* (searchTerm) {
      return self.state.groups;
    };
  }

  initializeGroups = () => {
    let groupsValue = [];

    this.state.inspectionJob.assets.filter(asset => {
      if (groupsValue.indexOf(asset.groupNumber) === -1) {
        groupsValue.push({ groupNumber: asset.groupNumber, groupName: asset.groupName });
      }
    });

    let groups = lodash.uniqWith(groupsValue, lodash.isEqual);

    groups.sort(function (a, b) {
      return (a.groupNumber - b.groupNumber);
    });

    this.state.groups = groups;

    this.state.groups = groups.map(group => {
      return {
        value: group,
        selected: false
      };
    });
  }

  initializeSelection = () => {
    this.state.inspectionJob.assets.forEach(asset => {
      asset.selected = (asset.eAssetStatus !== AppConstants.eAssetStatus.Scrapped) && (asset.TestStatus === 'Started');
    });
    this.configureTestButtons();
  }

  isAssetSelectable = (asset) => {
    // asset cannot be selected if it has selected service levels with conflicting tests
    return (!ServiceLevelHelper.hasConflictingTests(asset));
  }

  isAssetScrapped = (asset) => {
    return (asset.eAssetStatus === AppConstants.eAssetStatus.Scrapped || asset.eAssetStatus === AppConstants.eAssetStatus.WeldRepair || asset.eAssetStatus === AppConstants.eAssetStatus.FailedInspection || asset.eAssetStatus === AppConstants.eAssetStatus.OSRepair);
  }
  getScrappedAssets = (asset) => {
    return (asset.lastTestResult && asset.lastTestResult.testName && asset.lastTestResult.testResult === AppConstants.eInspectionTestResult.Failed);
  }
  getOSRepairAssets = (asset) => {
    return (asset.lastTestResult && asset.lastTestResult.testResult === AppConstants.eInspectionTestResult.OSRepair);
  }
  getWeldRepairAssets = (asset) => {
    return (asset.lastTestResult && asset.lastTestResult.testResult === AppConstants.eInspectionTestResult.WeldRepair);
  }
  getFailedAssets = (asset) => {
    return (asset.lastTestResult && asset.lastTestResult.testResult === AppConstants.eInspectionTestResult.FailedInspection);
  }
  // OperationOrder is a property on each test in the asset's service level
  // If it's defined, each value should be unique
  // Collect all values into an array and see if there are duplicates
  isOperationOrderNotDefined = (asset) => {
    let isNotDefined = false;
    let operationOrders = [];
    asset.serviceLevels
      .filter(serviceLevel => serviceLevel.performed)
      .forEach(serviceLevel => {
        serviceLevel.tests.forEach(test => {
          let operationOrder = test.operationOrder;
          if (operationOrder === 0) {
            isNotDefined = true;
          }
          else if (operationOrders.includes(operationOrder)) {
            isNotDefined = true;
          }
          else {
            operationOrders.push(operationOrder);
          }
        });
      });
    return isNotDefined;
  }

  isOperationOrderNotDefinedForAssets = (selectedAssets) => {
    const isNotDefined = selectedAssets.some(asset => this.isOperationOrderNotDefined(asset));
    return isNotDefined;
  }

  isPreviousTestComplete = (selectedAssets, activeTest) => {
    if (!activeTest || !selectedAssets || !selectedAssets.length) {
      return false;
    }
    if (activeTest.operationOrder === 1) {
      // 1st operation - ok to enable
      return true;
    }
    let previousTestOperationOrder = activeTest.operationOrder - 1;
    // var result = selectedAssets.reduce((currentResult, asset) => {
    //   if (!asset.serviceLevels.length) {
    //     return false;
    //   }
    //   var serviceLevel = asset.serviceLevels[0];
    //   var previousTest = serviceLevel.tests.find(test => test.operationOrder === previousTestOperationOrder);
    //   if (!previousTest) {
    //     return false;
    //   }
    //   var testResults = asset.testResults.filter(test => test.testName === previousTest.testName);
    //   if (!testResults.length) {
    //     return false;
    //   }
    //   var testResult = testResults[0];
    //   switch (testResult.testResult) {
    //     case AppConstants.eInspectionTestResult.Passed:
    //     case AppConstants.eInspectionTestResult.Failed:        
    //     case AppConstants.eInspectionTestResult.TemporarilyFailed:
    //     case AppConstants.eInspectionTestResult.OSRepair:
    //     case AppConstants.eInspectionTestResult.WeldRepair:
    //       return true;

    //     case AppConstants.eInspectionTestResult.NotPerformed:
    //       return (testResult.testedBy !== null);

    //     default:
    //       return false;
    //   }
    // }, true);
    // return result;
    let status = 0;
    selectedAssets.forEach(asset => {
      if (!asset.serviceLevels.length) {
        return false;
      }
      const serviceLevel = asset.serviceLevels[0];
      const previousTest = serviceLevel.tests.find(test => test.operationOrder === previousTestOperationOrder);
      if (!previousTest) {
        return false;
      }
      const testResults = asset.testResults.filter(test => test.testName === previousTest.testName);
      if (!testResults.length) {
        return false;
      }
      const testResult = testResults[0];
      switch (testResult.testResult) {
        case AppConstants.eInspectionTestResult.Passed:
        case AppConstants.eInspectionTestResult.Failed:
        case AppConstants.eInspectionTestResult.TemporarilyFailed:
        case AppConstants.eInspectionTestResult.FailedInspection:
        case AppConstants.eInspectionTestResult.OSRepair:
        case AppConstants.eInspectionTestResult.WeldRepair:
        case AppConstants.eInspectionTestResult.Unteco:
          if (asset.IsUnTecoed && asset.lastTestResult && asset.lastTestResult.testName === testResult.testName) {
            status = status;
          }
          else if (testResult.testResult === AppConstants.eInspectionTestResult.OSRepair) {
            status = status;
          }
          else if (testResult.testedBy !== null)
            status = status + 1;
          break;
        case AppConstants.eInspectionTestResult.NotPerformed:
          const statusIcon = this.getStatusAssetsIcon([asset.lastTestResult], [asset]);
          if (asset.IsUnTecoed && asset.lastTestResult && statusIcon === InspectionTestIcons.NO_ICON && testResult.operationOrder > asset.lastTestResult.operationOrder) {
            status = status;
          }
          else if (testResult.testedBy !== null)
            status = status + 1;
          break;
        default:
          return false;
      }
    });
    if (status == selectedAssets.length)
      return true;
    else
      return false;
  }

  getSelectedCount = () => {
    return this.state.inspectionJob.assets.reduce((acc, curr) => {
      return (curr.selected && this.isAssetSelectable(curr)) ? acc + 1 : acc;
    }, 0);
  }

  isInspecting = () => {
    return (this.state.selectedJob && this.state.selectedJob.isInspecting);
  }

  isWaiting = () => {
    return (this.state.selectedJob && this.state.selectedJob.isWaiting);
  }

  assetsWithoutServiceOrderNumbers = (selectedAssets) => {
    if (!SAPService.isSAPFacility() || !selectedAssets.length) {
      return false;
    }
    return selectedAssets.some(asset => !asset.serviceOrderNumber);
  }
  getReceivableAssets = (selectedAssets) => {
    const assets = this.state.inspectionJob.assets;
    let denyAssetsList = [];
    selectedAssets.forEach(asset => {
      let denyAsset = assets.filter(denyAsset => denyAsset.assetId == asset.assetId && denyAsset.sapStatus != AppConstants.AssetSAPStatus.TECO);
      if (denyAsset.length > 0) {
        denyAssetsList.push(denyAsset.assetId)
      }
    });
    if (denyAssetsList.length > 0) {
      return false;
    }
    else {
      return true;
    }
  }
  reasonCannotFailSelection = (testName) => {
    const selectedAssets = this.getSelectedAssets();
    const selectedCount = selectedAssets.length;
    // var familyCodeIds  = selectedAssets.map(asset => asset.familyCodeId);
    // var distinctList   = Utils.getDistinct(familyCodeIds);
    // var isDistinct     = (distinctList.length === 1);

    if (selectedCount === 0) {
      // no selection - no displayable reason cannot fail
      return '';
    }

    // if (!isDistinct) {
    //   // can't fail selection if they don't all have the same family code
    //   return 'different Family Codes selected';
    // }

    // can't fail if any selected assets are already scrapped
    const anyScrappedAssets = selectedAssets.some(asset => this.isAssetScrapped(asset));
    if (anyScrappedAssets) {
      if (selectedCount === 1) {
        if (selectedAssets[0].eAssetStatus === AppConstants.eAssetStatus.Scrapped)
          return 'The selected asset is already scrapped';
        else if (selectedAssets[0].eAssetStatus === AppConstants.eAssetStatus.OSRepair)
          return 'The selected asset is in OS/Machining Repair. You cannot proceed further';
        else if (selectedAssets[0].eAssetStatus === AppConstants.eAssetStatus.WeldRepair)
          return 'The selected asset is in Weld Repair. You cannot proceed further';
      }
      return 'At least one of the selected assets is already scrapped';
    }

    // can't fail if any selected assets are already shipped
    const anyShippedAssets = selectedAssets.some(asset => asset.hasShipped);
    if (anyShippedAssets) {
      if (selectedCount === 1) {
        return 'The selected asset has already been shipped';
      }
      return 'At least one of the selected assets has already been shipped';
    }

    // so far, it's enabled - verify we're not on the 'Final Inspection', 'Paint', or 'Stamp/Band' tasks
    // can't set it to 'Fail' in UI
    let prefix = 'Unable to fail - ';
    if (testName === 'Final Inspection') {
      return prefix + 'Final Inspection selected';
    }
    if (testName === 'Paint') {
      return prefix + 'Paint selected';
    }
    if (testName === 'Stamp/Band') {
      return prefix + 'Stamp/Band selected';
    }
    // no reason to not allow failure of selection
    return '';
  }

  reasonCannotWeldRepairSelection = (testName) => {
    const selectedAssets = this.getSelectedAssets();
    const selectedCount = selectedAssets.length;
    // var familyCodeIds  = selectedAssets.map(asset => asset.familyCodeId);
    // var distinctList   = Utils.getDistinct(familyCodeIds);
    // var isDistinct     = (distinctList.length === 1);

    if (selectedCount === 0) {
      // no selection - no displayable reason cannot fail
      return '';
    }

    // if (!isDistinct) {
    //   // can't fail selection if they don't all have the same family code
    //   return 'different Family Codes selected';
    // }

    // can't fail if any selected assets are already scrapped
    const anyScrappedAssets = selectedAssets.some(asset => this.isAssetScrapped(asset));
    if (anyScrappedAssets) {
      if (selectedCount === 1) {
        if (selectedAssets[0].eAssetStatus === AppConstants.eAssetStatus.Scrapped)
          return 'The selected asset is already scrapped';
        else if (selectedAssets[0].eAssetStatus === AppConstants.eAssetStatus.OSRepair)
          return 'The selected asset is in OS/Machining Repair. You cannot proceed further';
        else if (selectedAssets[0].eAssetStatus === AppConstants.eAssetStatus.WeldRepair)
          return 'The selected asset is in Weld Repair. You cannot proceed further';
      }
      return 'At least one of the selected assets is already scrapped';
    }

    // can't fail if any selected assets are already shipped
    const anyShippedAssets = selectedAssets.some(asset => asset.hasShipped);
    if (anyShippedAssets) {
      if (selectedCount === 1) {
        return 'The selected asset has already been shipped';
      }
      return 'At least one of the selected assets has already been shipped';
    }

    // so far, it's enabled - verify we're not on the 'Final Inspection', 'Paint', or 'Stamp/Band' tasks
    // can't set it to 'Fail' in UI
    let prefix = 'Unable to weld repair - ';
    if (testName === 'Final Inspection') {
      return prefix + 'Final Inspection selected';
    }
    if (testName === 'Paint') {
      return prefix + 'Paint selected';
    }
    if (testName === 'Stamp/Band') {
      return prefix + 'Stamp/Band selected';
    }
    // no reason to not allow failure of selection
    return '';
  }
  getTimeTrackingResults = (selectedAssets, testName) => {
    if ((selectedAssets === null) || !selectedAssets.length) {
      return [];
    }

    let testResults = [];
    selectedAssets.forEach(selectedAsset => {
      selectedAsset.timeTrackingResults.forEach(testResult => {
        if (testResult.testName === testName) {
          testResults.push(testResult);
        }
      });
    });
    return testResults;
  }

  getTestResults = (selectedAssets, testName) => {
    if ((selectedAssets === null) || !selectedAssets.length) {
      return [];
    }

    let testResults = [];
    selectedAssets.forEach(selectedAsset => {
      selectedAsset.testResults.forEach(testResult => {
        if (testResult.testName === testName) {
          testResults.push(testResult);
        }
      });
    });
    return testResults;
  }
  getAllTestResults = (selectedAssets) => {
    if ((selectedAssets === null) || !selectedAssets.length) {
      return [];
    }

    let testResults = [];
    selectedAssets.forEach(selectedAsset => {
      selectedAsset.testResults.forEach(testResult => {
        testResults.push(testResult);
      });
    });
    return testResults;
  }
  getPassedStatus = (tests) => {
    let status = false;
    tests.forEach(test => {
      if (test.testName === 'Pressure Test' && test.status === 'Pressure Test Passed') {
        status = true;
      }
    });
    return status;
  }
  getFailedStatus = (tests) => {
    let status = false;
    tests.forEach(test => {
      if (test.testName === 'Pressure Test' && test.status === 'Pressure Test Failed') {
        status = true;
      }
    });
    return status;
  }

  // getPassedStatus = (tests) => {
  //   if (!tests || !tests.length) {
  //       return null;
  //   }

  //   var status = false;
  //   tests.forEach(test => {
  //       if(test.testName === 'Pressure Test') {
  //           if (DateTimeFormatter.formatDate(test.testDate) === '') {
  //               status = '';
  //           } else {
  //               switch (test.testResult) {
  //                   case AppConstants.eInspectionTestResult.Failed:
  //                       status = false;
  //                       break;

  //                   case AppConstants.eInspectionTestResult.Passed:
  //                       status = true;
  //                       break;
  //               }
  //           }
  //       }
  //   });
  //   return status;
  // }


  getCommonTestDate = (matchingTests) => {
    let result = 'sentinel';
    matchingTests.forEach(test => {
      let testDate = DateTimeFormatter.formatDate(test.stopTime || test.testDate);
      if (testDate === '') {
        if ((result === 'sentinel') || (result === '')) {
          result = '';
        }
        else {
          result = '[different dates]';
        }
      }

      if (result === 'sentinel') {
        // set the return value initially
        result = testDate;
      }
      else if (result !== testDate) {
        // if it has a different value, reset it
        // otherwise, if all tests have the same value, that's what will be returned
        result = '[different dates]';
      }
    });
    return (result === 'sentinel') ? '' : result;
  }

  getCommonTechnician = (matchingTests) => {
    let result = 'sentinel';
    matchingTests.forEach(test => {
      if (result === 'sentinel') {
        // set the return value initially
        result = ((test.technician && test.technician.name) || test.testedBy);
      }
      else if (result !== ((test.technician && test.technician.name) || test.testedBy)) {
        // if it has a different value, reset it
        // otherwise, if all tests have the same value, that's what will be returned
        result = '[different techs]';
      }
    });
    result = (result === 'sentinel') ? '' : result;
    return result;
  }

  getOperationCode = (matchingTests) => {
    let result = '';
    matchingTests.forEach(test => {
      let operationCode = test.operationCode;
      if (operationCode !== '') {
        result = operationCode;
      }
    });
    return result;
  }

  getOperationOrder = (selectedAssets, testName) => {
    let result = '';
    selectedAssets.forEach(asset => {
      asset.serviceLevels.forEach(serviceLevel => {
        serviceLevel.tests.forEach(test => {
          if (test.testName === testName) {
            if (result === '') {
              result = test.operationOrder;
            }
          }
        });
      });
    });
    return result;
  }

  getLastTestResultName = (selectedAssets) => {
    let result = '';
    selectedAssets.forEach(asset => {
      if (result === '') {
        result = (asset.lastTestResult && asset.lastTestResult.testName);
      }
      else {
        let testName = (asset.lastTestResult && asset.lastTestResult.testName);
        if (testName !== result) {
          result = '<>';
        }
      }
    });
    return result === '<>' ? '' : result;
  }

  getTestDuration = (selectedAssets, testName) => {
    if (selectedAssets.length === 0) {
      return '';
    }

    const allResults = [].concat.apply([], selectedAssets.map(asset => asset.timeTrackingResults || []));
    const filteredTests = testName
      ? allResults.filter(test => test.testName === testName)
      : allResults.filter(() => true);
    const duration = filteredTests.reduce((acc, curr) => {
      return (acc + curr.calculatedDuration);
    }, 0);

    const formattedDuration = DateTimeFormatter.formatDuration(duration);
    if (!formattedDuration || (formattedDuration === '0s')) {
      return filteredTests.length ? '0m' : '';
    }

    return formattedDuration;
  }
  getRSDuration = (selectedAssets, testName) => {
    if (selectedAssets.length === 0) {
      return '';
    }
    const duration = selectedAssets.reduce((acc, curr) => {
      return (acc + curr.rsTotal);
    }, 0);

    // var formattedDuration = DateTimeFormatter.formatDurationNew(duration);
    // if (!formattedDuration || (formattedDuration === '0s')) {
    //   return selectedAssets.length ? '0m' : '';
    // }
    const formattedDuration = duration.toFixed(1) + 'm';
    return formattedDuration;
  }
  // duplicated in JobProgressAssetsPage.jsx
  getStatusIcon = (tests) => {
    if (!tests || !tests.length) {
      return null;
    }

    let statusIcon = null;
    tests.forEach(test => {
      if (DateTimeFormatter.formatDate(test.testDate) === '') {
        statusIcon = InspectionTestIcons.NO_ICON;
      }
      else {
        if (!test || !test.isActive) {
          // if icon has either not been set (!statusIcon) or has been set and matches the desired value, set/keep that value
          // otherwise, set it to null
          statusIcon = (!statusIcon || (statusIcon && (statusIcon === InspectionTestIcons.NO_ICON)))
            ? InspectionTestIcons.NO_ICON
            : null;
        }
        else {
          switch (test.testResult) {
            case AppConstants.eInspectionTestResult.Failed:
              statusIcon = (!statusIcon || (statusIcon && (statusIcon === InspectionTestIcons.FAILED)))
                ? InspectionTestIcons.FAILED
                : null;
              break;

            case AppConstants.eInspectionTestResult.TemporarilyFailed:
            case AppConstants.eInspectionTestResult.FailedInspection:
              statusIcon = (!statusIcon || (statusIcon && (statusIcon === InspectionTestIcons.TEMP_FAILURE)))
                ? InspectionTestIcons.TEMP_FAILURE
                : null;
              break;
            case AppConstants.eInspectionTestResult.Passed:
              statusIcon = (!statusIcon || (statusIcon && (statusIcon === InspectionTestIcons.PASSED)))
                ? InspectionTestIcons.PASSED
                : null;
              break;

            case AppConstants.eInspectionTestResult.OSRepair:
              statusIcon = (!statusIcon || (statusIcon && (statusIcon === InspectionTestIcons.OS_REPAIR)))
                ? InspectionTestIcons.OS_REPAIR
                : null;
              break;

            case AppConstants.eInspectionTestResult.WeldRepair:
              statusIcon = (!statusIcon || (statusIcon && (statusIcon === InspectionTestIcons.WELD_REPAIR)))
                ? InspectionTestIcons.WELD_REPAIR
                : null;
              break;

            case AppConstants.eInspectionTestResult.NotPerformed:
              statusIcon = (!statusIcon || (statusIcon && (statusIcon === InspectionTestIcons.NOT_PERFORMED)))
                ? InspectionTestIcons.NOT_PERFORMED
                : null;
              break;
            case AppConstants.eInspectionTestResult.Unteco:
              statusIcon = (!statusIcon || (statusIcon && (statusIcon === InspectionTestIcons.NO_ICON)))
                ? InspectionTestIcons.NO_ICON
                : null;
              break;
          }
        }
      }
    });
    if (statusIcon === null) {
      statusIcon = InspectionTestIcons.INDETERMINATE;
    }
    return statusIcon;
  }
  getStatusAssetsIcon = (tests, assets) => {
    if (!tests || !tests.length) {
      return null;
    }

    let statusIcon = null;
    tests.forEach(test => {
      let islatest = false;
      assets.forEach(asset => {
        if (asset.assetId == test.assetId) {
          if (asset.lastTestResult && asset.lastTestResult.testName);
          {
            if ((asset.lastTestResult.testName == test.testName) && asset.IsUnTecoed) {
              islatest = true;
            }
          }
        }
      });
      if (islatest) {
        statusIcon = InspectionTestIcons.NO_ICON;
      }
      else if (DateTimeFormatter.formatDate(test.testDate) === '') {
        statusIcon = InspectionTestIcons.NO_ICON;
      }
      else {
        if (!test || !test.isActive) {
          // if icon has either not been set (!statusIcon) or has been set and matches the desired value, set/keep that value
          // otherwise, set it to null
          statusIcon = (!statusIcon || (statusIcon && (statusIcon === InspectionTestIcons.NO_ICON)))
            ? InspectionTestIcons.NO_ICON
            : null;
        }
        else {
          switch (test.testResult) {
            case AppConstants.eInspectionTestResult.Failed:
              statusIcon = (!statusIcon || (statusIcon && (statusIcon === InspectionTestIcons.FAILED)))
                ? InspectionTestIcons.FAILED
                : null;
              break;

            case AppConstants.eInspectionTestResult.TemporarilyFailed:
            case AppConstants.eInspectionTestResult.FailedInspection:
              statusIcon = (!statusIcon || (statusIcon && (statusIcon === InspectionTestIcons.TEMP_FAILURE)))
                ? InspectionTestIcons.TEMP_FAILURE
                : null;
              break;
            case AppConstants.eInspectionTestResult.Passed:
              statusIcon = (!statusIcon || (statusIcon && (statusIcon === InspectionTestIcons.PASSED)))
                ? InspectionTestIcons.PASSED
                : null;
              break;

            case AppConstants.eInspectionTestResult.OSRepair:
              statusIcon = (!statusIcon || (statusIcon && (statusIcon === InspectionTestIcons.OS_REPAIR)))
                ? InspectionTestIcons.OS_REPAIR
                : null;
              break;

            case AppConstants.eInspectionTestResult.WeldRepair:
              statusIcon = (!statusIcon || (statusIcon && (statusIcon === InspectionTestIcons.WELD_REPAIR)))
                ? InspectionTestIcons.WELD_REPAIR
                : null;
              break;

            case AppConstants.eInspectionTestResult.NotPerformed:
              statusIcon = (!statusIcon || (statusIcon && (statusIcon === InspectionTestIcons.NOT_PERFORMED)))
                ? InspectionTestIcons.NOT_PERFORMED
                : null;
              break;
            case AppConstants.eInspectionTestResult.Unteco:
              statusIcon = (!statusIcon || (statusIcon && (statusIcon === InspectionTestIcons.NO_ICON)))
                ? InspectionTestIcons.NO_ICON
                : null;
              break;
          }
        }
      }
    });
    if (statusIcon === null) {
      statusIcon = InspectionTestIcons.INDETERMINATE;
    }
    return statusIcon;
  }

  getStatusText = (tests) => {
    if (!tests || !tests.length) {
      return null;
    }

    let statusText = null;
    const statusPassText = <span className="passed test-status-text">P</span>;
    const statusFailText = <span className="failed test-status-text">F</span>;
    tests.forEach(test => {
      if (test.testName === 'Pressure Test') {
        if (DateTimeFormatter.formatDate(test.testDate) === '') {
          statusText = '';
        } else {
          switch (test.testResult) {
            case AppConstants.eInspectionTestResult.Failed:
              statusText = (!statusText || (statusText && (statusText === statusFailText)))
                ? statusFailText
                : null;
              break;

            case AppConstants.eInspectionTestResult.Passed:
              statusText = (!statusText || (statusText && (statusText === statusPassText)))
                ? statusPassText
                : null;
              break;
          }
        }
      }
    });
    if (statusText === null) {
      statusText = '';
    }
    return statusText;
  }


  IsFinalAlreadyPassed = (tests) => {
    if (!tests || !tests.length) {
      return null;
    }
    let status = 0;
    tests.forEach(test => {
      if (test.testName.includes('Final')) {
        if (DateTimeFormatter.formatDate(test.testDate) === '') {
          status = 0;
        } else {
          switch (test.testResult) {
            case AppConstants.eInspectionTestResult.Failed:
              status = 0;
              break;

            case AppConstants.eInspectionTestResult.Passed:
              status = status + 1;
              break;
          }
        }
      }
    });
    if (status === 0)
      return false;
    else
      return true;
  }
  IsTestAlreadyPassed = (tests) => {
    if (!tests || !tests.length) {
      return false;
    }

    let status = 0;
    tests.forEach(test => {
      if (DateTimeFormatter.formatDate(test.testDate) === '') {
        //status = 0;
      }
      else {
        if (!test || !test.isActive) {
          //status = 0;
        }
        else {
          switch (test.testResult) {
            case AppConstants.eInspectionTestResult.Failed:
            case AppConstants.eInspectionTestResult.FailedInspection:
              //status = 0;
              break;
            case AppConstants.eInspectionTestResult.TemporarilyFailed:
              //status = 0;
              break;

            case AppConstants.eInspectionTestResult.Passed:
              //if(!test.testName.includes('Final')) 
              status = status + 1;
              break;
            case AppConstants.eInspectionTestResult.OSRepair:
              //status = 0;
              break;

            case AppConstants.eInspectionTestResult.WeldRepair:
              //status = 0;
              break;

            case AppConstants.eInspectionTestResult.NotPerformed:
              //status = 0;
              break;

          }
        }
      }
    });
    if (status === 0)
      return false;
    else
      return true;
  }
  IsAssetTestAlreadyPassed = (tests, assets) => {
    if (!tests || !tests.length) {
      return false;
    }

    let status = 0;
    tests.forEach(test => {
      let islatest = false;
      assets.forEach(asset => {
        if (asset.assetId == test.assetId) {
          if (asset.lastTestResult && asset.lastTestResult.testName);
          {
            if ((asset.lastTestResult.testName == test.testName) && asset.IsUnTecoed) {
              islatest = true;
            }
          }
        }
      });
      if (islatest) {
        status = 0;
      }
      else if (DateTimeFormatter.formatDate(test.testDate) === '') {
        //status = 0;
      }
      else {
        if (!test || !test.isActive) {
          //status = 0;
        }
        else {
          switch (test.testResult) {
            case AppConstants.eInspectionTestResult.Failed:
            case AppConstants.eInspectionTestResult.FailedInspection:
              //status = 0;
              break;
            case AppConstants.eInspectionTestResult.TemporarilyFailed:
              //status = 0;
              break;

            case AppConstants.eInspectionTestResult.Passed:
              //if(!test.testName.includes('Final')) 
              status = status + 1;
              break;
            case AppConstants.eInspectionTestResult.OSRepair:
              //status = 0;
              break;

            case AppConstants.eInspectionTestResult.WeldRepair:
              //status = 0;
              break;

            case AppConstants.eInspectionTestResult.NotPerformed:
              //status = 0;
              break;
          }
        }
      }
    });
    if (status === 0)
      return false;
    else
      return true;
  }
  IsTestAlreadyFailed = (tests) => {
    if (!tests || !tests.length) {
      return false;
    }

    let status = 0;
    tests.forEach(test => {
      if (DateTimeFormatter.formatDate(test.testDate) === '') {
        //status = 0;
      }
      else {
        if (!test || !test.isActive) {
          //status = 0;
        }
        else {
          switch (test.testResult) {
            case AppConstants.eInspectionTestResult.Failed:
            case AppConstants.eInspectionTestResult.FailedInspection:
              status = status + 1;
              break;
            case AppConstants.eInspectionTestResult.TemporarilyFailed:
              //status = 0;
              break;

            case AppConstants.eInspectionTestResult.Passed:
              //status = 0;           
              break;
            case AppConstants.eInspectionTestResult.OSRepair:
              //status = 0;
              break;

            case AppConstants.eInspectionTestResult.WeldRepair:
              status = status + 1;
              break;

            case AppConstants.eInspectionTestResult.NotPerformed:
              //status = 0;
              break;
          }
        }
      }
    });
    if (status === 0)
      return false;
    else
      return true;
  }
  displayChart = (chartData,assetNonAPTChartData=[],selectedAsset) => {
    Dialog.showDialog(<AssetChartDialog controller={new AssetChartController(chartData,assetNonAPTChartData,selectedAsset)} header='Pressure Test Chart' />);
  }

  getChart = () => {
    const asset = this.getSelectedAsset();
   
    if (!asset && (!asset.hasChart || !asset.hasnonAPTCharts)) {
      return;
    }
    const assetNonAPTChartData = asset.nonAPTCharts;
    if(asset.chartHeaderId !== Utils.emptyGuid)
    {
      ajax.get('lookup/getAssetChart', { chartHeaderId: asset.chartHeaderId }).then(result => {
        if (result) {
          this.displayChart(result,assetNonAPTChartData,asset);
          return;
        }
        else {        
            notification.action('warning').post({ msg: 'No chart data available for asset with serial number ' + asset.primarySerialNumber, title: 'Asset Chart Not Found' });
        }
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });  // AssetChartDto
    }
    if(assetNonAPTChartData.length>0)
    {
      this.displayChart(null,assetNonAPTChartData,asset);
      return;
    }
    notification.action('warning').post({ msg: 'No chart data available for asset with serial number ' + asset.primarySerialNumber, title: 'Asset Chart Not Found' });

  }

  showGaugesPartNo = (test) => {
    const jobId = this.state.inspectionJob.jobId;
    const testName = test.name;
    const selectedAssets = this.getSelectedAssets();
    const selectedCount = selectedAssets.length;
    const selectedJobStatus = this.state.selectedJob.isComplete;
    let isNA = false;
    if (test.name === 'Mag Particle Inspection (MPI)' || test.name === 'Thickness (UT)'
    || test.name === 'Gauge' || test.name === 'Pressure Test') {
      selectedAssets.forEach(asset => {
        const result = asset.testResults.filter(x => x.testName === test.name);
        if(result[0].NotApplicable){
          isNA = true;
        }
      });
    }

    if (selectedCount === 0) {
      notification.action('warning').post('You must select at least one asset to pass the ' + test.name + ' test');
      return;
    }

    co(this, function* () {
      Dialog.showDialog(<InspectionGaugesDialog controller={new InspectionGaugesController(jobId, testName, selectedAssets, selectedJobStatus, isNA)} header='Gauge Selection' />);
    });
  }

  passTest = (test) => {
    const jobId = this.state.inspectionJob.jobId;
    const testName = test.name;
    const selectedAssets = this.getSelectedAssets();
    const selectedCount = selectedAssets.length;

    if (selectedCount === 0) {
      notification.action('warning').post('You must select at least one asset to pass the ' + test.name + ' test');
      return;
    }

    // co(this, function*() {
    const message = 'Please confirm you wish to pass the ' + test.name + ' operation for the selected asset' + (selectedCount === 1 ? '' : 's');
    Dialog.showDialog(<InspectionPassedDialog controller={new InspectionPassedController(jobId, testName, selectedAssets, false)} header='Confirm Pass Test'>{message}</InspectionPassedDialog>);
    // });
  }

  failTest = (test) => {
    const jobId = this.state.inspectionJob.jobId;
    const isFailInspection = this.state.inspectionJob.failInspection;
    const testName = test.name;
    const customerName = this.state.selectedJob.customerName;
    const selectedAssets = this.getSelectedAssets();
    if ((selectedAssets === null) || !selectedAssets.length) {
      // this shouldn't happen - button should only be enabled if at least one asset is selected
      return;
    }
    const header = 'Scrap Asset' + (selectedAssets.length === 1 ? '' : 's');
    Dialog.showDialog(<InspectionFailedDialog controller={new InspectionFailedController(jobId, testName, selectedAssets, false, isFailInspection, customerName)} header={header} />);
  }
  tecoTest = () => {
    const selectedAssets = this.getSelectedAssets().map((asset) => {
      asset.sapStatus = AppConstants.AssetSAPStatus.REL;

      return asset;
    });
    if ((selectedAssets === null) || !selectedAssets.length) {
      // this shouldn't happen - button should only be enabled if at least one asset is selected
      return;
    }

    const parameters = {
      selectedAssets: selectedAssets,
      today: DateTimeFormatter.formatDate(DateTimeFormatter.today())
    };
    ajax.post('inspection/tecoTest', parameters).then(results => {
      const assetIds = selectedAssets.map(a => a.assetId);
      messages.channel('inspection').action('saved').post({ jobId: this.state.inspectionJob.jobId, assetIds: assetIds });
      notification.action('success').post('Teco Successful');
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    }); // out: IList<TimeTrackingDto>
  }
  unTecoTest = () => {
    const selectedAssets = this.getSelectedAssets().map((asset) => {
      asset.sapStatus = AppConstants.AssetSAPStatus.TECO;

      return asset;
    });
    if ((selectedAssets === null) || !selectedAssets.length) {
      return;
    }
    this.state.untecoInProgress = true;
    this.commit();
    var parameters = {
      selectedAssets: selectedAssets,
      today: DateTimeFormatter.formatDate(DateTimeFormatter.today())
    };
    ajax.post('inspection/untecoTest', parameters).then(results => {
      const assetIds = selectedAssets.map(a => a.assetId);
      messages.channel('inspection').action('saved').post({ jobId: this.state.inspectionJob.jobId, assetIds: assetIds });
      this.state.untecoInProgress = false;
      this.commit();
      notification.action('success').post('UnTeco Successful');
    }).catch(err => {
      this.state.untecoInProgress = false;
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error' });
    }); // out: IList<TimeTrackingDto>
  }
  notPerformedTest = (test) => {
    const selectedAssets = this.getSelectedAssets();
    const selectedCount = selectedAssets.length;
    if (selectedCount === 0) {
      notification.action('warning').post('You must select at least one asset to NOT PERFORM the ' + test.name + ' test');
      return;
    }

    const jobId = this.state.inspectionJob.jobId;
    co(function* () {
      yield Dialog.showDialogWaitForResult(<InspectionNotPerformedDialog controller={new InspectionNotPerformedController(jobId, 'notPerformedTest', 'NOT PERFORM', test.name, selectedAssets, test.name + ' test Not Performed')} header='Confirm Update Test Result' />);
    });
  }

  osRepair = (test) => {
    const jobId = this.state.inspectionJob.jobId;
    const testName = test.name;
    const selectedAssets = this.getSelectedAssets();
    const selectedCount = selectedAssets.length;

    if (selectedCount === 0) {
      notification.action('warning').post('You must select at least one asset to pass the ' + test.name + ' test');
      return;
    }

    co(this, function* () {
      const message = 'Please confirm you wish to OS/Machining repair the ' + test.name + ' operation for the selected asset' + (selectedCount === 1 ? '' : 's');
      Dialog.showDialog(<InspectionPassedDialog controller={new InspectionPassedController(jobId, testName, selectedAssets, true)} header='Confirm OS/Machining Repair Test'>{message}</InspectionPassedDialog>);
    });
  }
  fixPlant = (asset) => {
    const jobId = this.state.inspectionJob.jobId;
    co(this, function* () {
      Dialog.showDialog(<ComponentUpdateDialog controller={new ComponentUpdateController(asset,AppConstants.NoteTypes.Job,jobId)} header='Fix Maintenance Plant'></ComponentUpdateDialog>);
    });
  }

  weldRepair = (test) => {
    const jobId = this.state.inspectionJob.jobId;
    const testName = test.name;
    const customerName = '';
    const selectedAssets = this.getSelectedAssets();
    if ((selectedAssets === null) || !selectedAssets.length) {
      // this shouldn't happen - button should only be enabled if at least one asset is selected
      return;
    }
    const header = 'Weld Repair Asset' + (selectedAssets.length === 1 ? '' : 's');
    Dialog.showDialog(<InspectionFailedDialog controller={new InspectionFailedController(jobId, testName, selectedAssets, true, false, customerName)} header={header} />);
  }

  recordInspectionTime = (test) => {
    const jobId = this.state.inspectionJob.jobId;
    const testName = test.name;
    const selectedAssets = this.getSelectedAssets();
    if ((selectedAssets === null) || !selectedAssets.length) {
      // this shouldn't happen - button should only be enabled if at least one asset is selected
      return;
    }

    co(function* () {
      yield Dialog.showDialogWaitForResult(<RecordInspectionTimeDialog controller={new RecordInspectionTimeController(jobId, testName, selectedAssets)} header='Record Inspection Time' />);
    });
  }

  addReworkTime = (test) => {
    const jobId = this.state.inspectionJob.jobId;
    const testName = test.name;
    const selectedAssets = this.getSelectedAssets();
    if ((selectedAssets === null) || !selectedAssets.length) {
      // this shouldn't happen - button should only be enabled if at least one asset is selected
      return;
    }

    co(function* () {
      yield Dialog.showDialogWaitForResult(<AddReworkTimeDialog controller={new AddReworkTimeController(jobId, testName, selectedAssets)} header='Add Rework Time' />);
    });
  }


  showGaugesDialog = (test) => {
    // var jobId          = this.state.inspectionJob.jobId;
    const testName = test.name;
    // var selectedAssets = this.getSelectedAssets();
    // const notes = '';
    // if ((selectedAssets === null) || !selectedAssets.length) {
    //   // this shouldn't happen - button should only be enabled if at least one asset is selected
    //   return;
    // }
    co(function* () {
      Dialog.showDialog(<InspectionTestGaugesDialog
        controller={new InspectionTestGaugesController(testName)} />);
    });
  }

  addTestNotes = (test) => {
    const jobId = this.state.inspectionJob.jobId;
    const testName = test.name;
    const selectedAssets = this.getSelectedAssets();
    let notes = '';
    if ((selectedAssets === null) || !selectedAssets.length) {
      // this shouldn't happen - button should only be enabled if at least one asset is selected
      return;
    }
    const assetIds = selectedAssets.map(asset => asset.jobSOWId);
    const assetId = assetIds.length > 0 ? assetIds[0] : Utils.emptyGuid;
    const param = {
      jobId: jobId,
      testName: testName,
      assetId: assetId,
    }
    ajax.post('lookup/getInspectionTestNotes', param).then(result => {
      if (result) {
        Dialog.showDialog(<InspectionTestNotesDialog controller={new InspectionTestNotesController(jobId, testName, assetId, result.notesId, result.notes)} />);
      }
      else {
        Dialog.showDialog(<InspectionTestNotesDialog controller={new InspectionTestNotesController(jobId, testName, assetId)} />);
      }
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }

  loadTestResults = (jobId, selectedAssets) => {
    selectedAssets.forEach(asset => {
      const parameters = {
        jobId: jobId,
        assetId: asset.jobSOWId
      };
      ajax.get('lookup/getTimeTrackingResults', parameters).then(results => {
        asset.timeTrackingResults = results;
        this.commit();
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });   // out: IList<TimeTrackingDto>
    });
  }

  displayScrapSignature = (scrapSignature) => {
    Dialog.showDialog(<AssetScrapSignatureDialog controller={new AssetScrapSignatureController(scrapSignature)} header='Scrap Signature' />);
  }

  displayStampBandData = (stampBandData) => {
    Dialog.showDialog(<AssetStampBandDialog controller={new AssetStampBandController(stampBandData)} header='Stamp / Band' />);
  }

  getStampBandData = () => {
    const asset = this.getSelectedAsset();
    if (!asset) {
      return;
    }
    else if (asset.stampBandData) {
      this.displayStampBandData(asset.stampBandData);
      return;
    }

    const s = this.state;
    const parameters = {
      assetId: asset.assetId,
      customerId: s.selectedJob.customerId,
      jobId: s.selectedJob.id
    };

    ajax.get('lookup/getStampBandData', parameters).then(result => {
      if (result) {
        result.primarySerialNumber = asset.primarySerialNumber;
        asset.stampBandData = result;
        this.displayStampBandData(result);
      }
      else {
        notification.action('warning').post({ msg: 'No stamp/band data available for asset with serial number ' + asset.primarySerialNumber, title: 'Asset Stamp/Band Not Found' });
      }
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });   // out: StampBandDto
  }

  addTorqueValues = () => {
    const asset = this.getSelectedAsset();
    if (!asset) {
      return;
    }
    var isViewMode = asset.lastTestResult.testName === 'Final Inspection';
    Dialog.showDialog(<TorqueValuesDialog controller={new TorqueValuesController(asset, isViewMode)} />);
    return;
  }

  addDimensionValues = () => {
    const asset = this.getSelectedAsset();
    if (!asset) {
      return;
    }
    const isViewMode = asset.lastTestResult.testName === 'Final Inspection';
    Dialog.showDialog(<DimensionValuesDialog controller={new DimensionValuesController(asset, isViewMode)} />);
    return;
  }

  addThicknessReadings = () => {
    const asset = this.getSelectedAsset();
    if (!asset) {
      return;
    }
    const isViewMode = asset.lastTestResult.testName === 'Final Inspection';
    Dialog.showDialog(<ThicknessReadingsDialog controller={new ThicknessReadingsController(asset, isViewMode)} />);
    return;
  }

  toggleSelection = (assets) => {
    this.state.inspectionJob.assets.forEach(row => {
      const asset = assets.find(item => row.assetId == item.assetId && row.jobSOWId == item.jobSOWId);
      if (asset) {
        row.selected = true;
      }
      else {
        row.selected = false;
      }
    });
    this.configureTestButtons();
    this.commit();
  }

  setActiveTest = (activeTestName) => {
    this.state.activeTestName = activeTestName;
    this.commit();
  }

  getSelectedAssets = () => {
    const selectedAssets = this.state.inspectionJob.assets.filter(asset => asset.selected);
    return selectedAssets || [];
  }

  getSelectedAsset = () => {
    const selectedAssets = this.getSelectedAssets();
    return (selectedAssets.length === 1)
      ? selectedAssets[0]
      : null;
  }

  jobSearch = (jobSearch) => {
    return (searchTerm) => ajax.get('lookup/searchInspectionJobsForFacility', {
      facilityId: LoginService.loginInfo.facilityId,
      searchTerm: searchTerm
    }).then(result => result).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }

  getSelectedGroups = () => {
    return this.state.groups.filter(group => group.selected);
  }

  getVisibleAssets = () => {
    return this.state.inspectionJob.assets.filter(asset => {
      const selectedGroups = this.getSelectedGroups();
      if (!selectedGroups.length) {
        return asset;
      }

      for (let i = 0; i < selectedGroups.length; ++i) {
        const group = selectedGroups[i];
        if (asset.groupNumber === group.value.groupNumber) {
          return asset;
        }
      }
    });
  }
  getComponents = (asset) => {
    co(this, function* () {
      try {
        const param = {
          serviceorderNumber: asset[0].serviceOrderNumber
        }
        //this.state.componentvals = [];        
        var result = yield ajax.get('lookup/getComponent', param);
        if (result.length != 0) {
          Dialog.showDialog(<ComponentUpdateDialog controller={new ComponentUpdateController(asset,1,this.state.jobId)} header='Fix Maintenance Plant'></ComponentUpdateDialog>);
          //this.commit();
        }
      }
      catch (err) {
        notification.action('error').post({ msg: err.message, title: 'Error in loading components' });
      }
    });
  }
  selectAll = () => {
    this.getVisibleAssets().forEach(asset => asset.selected = true);
    this.configureTestButtons();
    this.commit();
  }

  selectScrapped = () => {
    this.selectNone();
    this.getVisibleAssets()
      .filter(asset => this.getScrappedAssets(asset))
      .forEach(asset => asset.selected = true);
  }

  selectNone = () => {
    this.getVisibleAssets().forEach(asset => asset.selected = false);
    this.configureTestButtons();
    this.commit();
  }

  configureTestButtons = () => {
    // disable all buttons + remove 'extra' buttons
    this.state.inspectionTests.forEach(inspectionTest => {
      inspectionTest.enabled = false;
    });
    this.state.extraTests = [];

    // find all selected service levels of the selected assets
    const serviceLevels = ServiceLevelHelper.findSelectedIntersectingServiceLevels(this.state.inspectionJob.assets);
    if (!serviceLevels.length) {
      return;
    }

    // find all tests of the service levels
    const allTestsToPerform = ServiceLevelHelper.findDistinctTests(serviceLevels);
    if (!allTestsToPerform.length) {
      return;
    }

    // enable all of the 'standard 9' buttons if they're selected
    const allTestsToPerformList = Imm.List(allTestsToPerform);
    this.state.inspectionTests.forEach(inspectionTest => {
      if (allTestsToPerformList.contains(inspectionTest.name)) {
        inspectionTest.enabled = true;
      }
    });

    const standardTests = Imm.List(this.state.inspectionTests.map(test => test.name));
    // add any dynamic buttons that don't match the standard 9
    allTestsToPerform.forEach(testToPerform => {
      if (!standardTests.contains(testToPerform)) {
        this.state.extraTests.push({ name: testToPerform, className: '', completed: 0, remaining: 0, enabled: true });
      }
    });
  }

  assetsAreSameFamilyCodeAndCWP = () => {
    const assets = this.getSelectedAssets();
    if (!assets.length) {
      return false;
    }
    // return false if either familyCodeId or cwpId don't match for all assets
    const familyCodeId = assets[0].familyCodeId;
    if (assets.some(asset => asset.familyCodeId !== familyCodeId)) {
      return false;
    }
    const cwpId = assets[0].cwpId;
    if (assets.some(asset => asset.cwpId !== cwpId)) {
      return false;
    }
    return true;
  }

  userHasAPTRights = () => {
    if (!SecurityRights.hasSecurityToken(LoginService.loginInfo, SecurityRights.Rights.aptUser)) {
      return false;
    }
    if (!SecurityRights.hasSecurityToken(LoginService.loginInfo, SecurityRights.Rights.allowAPTOverride)) {
      return false;
    }
    return true;
  }
  userHasTecoRights = () => {
    if (!SecurityRights.hasSecurityToken(LoginService.loginInfo, SecurityRights.Rights.TecoUnTeco)) {
      return false;
    }
    return true;
  }
  canEditPressureTests = () => {
    if (!this.userHasAPTRights()) {
      return false;
    }
    if (this.state.selectedJob.isComplete) {
      return false;
    }
    return true;
  }

  hasPassedFinalInspection = (asset) => {
    if (!asset || !asset.testResults) {
      return false;
    }
    const passedFinalInspection = asset.testResults.some(a => (a.testName === 'Final Inspection') && (a.testResult === AppConstants.eInspectionTestResult.Passed));
    return passedFinalInspection;
  }

  canAddReworkTime = (statusIcon, testUpdatedDate) => {
    if (this.state.selectedJob && this.state.selectedJob.isComplete) {
      return false;
    }
    const selectedAssets = this.getSelectedAssets();
    const isuntecoed = selectedAssets.some(asset => asset.IsUnTecoed);
    if (isuntecoed && testUpdatedDate != '' && statusIcon !== InspectionTestIcons.NOT_PERFORMED)
      return true;
    if (statusIcon !== InspectionTestIcons.PASSED) {
      return false;
    }
    if (!selectedAssets.length) {
      return false;
    }
    const anyPassedFinalInspectionAssets = selectedAssets.some(asset => this.hasPassedFinalInspection(asset));
    return !anyPassedFinalInspectionAssets;
  }

  canAddTorqueValues = (test) => {
    if (test && (test.name.includes("Torque"))) {
      const selectedAssets = this.getSelectedAssets();
      const status = this.previousTestStatus(selectedAssets, test) && !this.state.selectedJob.isComplete && selectedAssets.length ? false : true;
      this.state.isAddTorqueValueDisable = status;
    }
    else {
      this.state.isAddTorqueValueDisable = true;
    }
  }
  canTecoUnTeco = (test) => {
    // if(test && (test.name==="Final Inspection"))
    // {
    const selectedAssets = this.getSelectedAssets();
    const status = selectedAssets.length > 0 && this.state.selectedJob && !this.state.selectedJob.isComplete && selectedAssets.length ? true : false;
    this.state.istecountecoDisable = status;
    // }
    // else
    // {
    //   this.state.istecountecoDisable = false;
    // }
  }
  canAddDimensionValues = (test) => {
    if (test && (test.name.includes("Dimensional"))) {
      const selectedAssets = this.getSelectedAssets();
      const status = this.previousTestStatus(selectedAssets, test) && !this.state.selectedJob.isComplete && selectedAssets.length ? false : true;
      this.state.isAddDimensionValueDisable = status;
    }
    else {
      this.state.isAddDimensionValueDisable = true;
    }
  }

  previousTestStatus = (selectedAssets, activeTest) => {
    if (!activeTest || !selectedAssets || !selectedAssets.length) {
      return false;
    }
    if (activeTest.operationOrder === 1) {
      // 1st operation - ok to enable
      return true;
    }
    const previousTestOperationOrder = activeTest.operationOrder - 1;
    const result = selectedAssets.reduce((currentResult, asset) => {
      if (!asset.serviceLevels.length) {
        return false;
      }
      const serviceLevel = asset.serviceLevels[0];
      const previousTest = serviceLevel.tests.find(test => test.operationOrder === previousTestOperationOrder);
      if (!previousTest) {
        return false;
      }
      const testResults = asset.testResults.filter(test => test.testName === previousTest.testName);
      if (!testResults.length) {
        return false;
      }
      const testResult = testResults[0];
      switch (testResult.testResult) {
        case AppConstants.eInspectionTestResult.Passed:
          return true;
        default:
          return false;
      }
    }, true);
    return result;
  }

  canOverridePressureTests = () => {
    // all assets must have same family code & cwp + current user must have the APT User & Allow APT Override security rights
    if (this.state.selectedJob && this.state.selectedJob.isComplete) {
      return false;
    }
    const selectedAssets = this.getSelectedAssets();
    const anyPassedFinalInspectionAssets = selectedAssets.some(asset => this.hasPassedFinalInspection(asset));
    if (anyPassedFinalInspectionAssets) {
      return false;
    }
    const anyScrappedAssets = selectedAssets.some(asset => this.isAssetScrapped(asset));
    if (anyScrappedAssets) {
      return false;
    }
    if (!this.assetsAreSameFamilyCodeAndCWP()) {
      return false;
    }
    if (!this.userHasAPTRights()) {
      return false;
    }
    return true;
  }

  getCommonFamilyCodeId = () => {
    if (!this.canOverridePressureTests()) {
      return null;
    }
    return this.getSelectedAssets()[0].familyCodeId;
  }

  getCommonCWPId = () => {
    if (!this.canOverridePressureTests()) {
      return null;
    }
    return this.getSelectedAssets()[0].cwpId;
  }

  getBillOfMaterial = (serviceLevel) => {
    if (!serviceLevel) {
      return '';
    }
    else if (!serviceLevel.billsOfMaterial || !serviceLevel.billsOfMaterial.length) {
      return 'No Bill of Material';
    }
    const bom = serviceLevel.billsOfMaterial[0];
    return bom.description;
  }

  canEdit3rdPartyJobNumber = () => {
    const job = this.state.selectedJob;
    return (job && (LoginService.isAdministrator || !job.isComplete));
  }

  edit3rdPartyJobNumber = () => {
    if (!this.canEdit3rdPartyJobNumber()) {
      return;
    }

    const job = this.state.selectedJob;
    const jobId = job.id;
    const thirdPartyJobNumber = job.thirdPartyJobNumber;
    const header = 'Modify Third Party Job Number for job # ' + job.jobNumber;

    co(function* () {
      yield Dialog.showDialogWaitForResult(<EditThirdPartyJobNumberDialog controller={new EditThirdPartyJobNumberController(jobId, thirdPartyJobNumber)} header={header} />);
    });
  }

  canEditCertificate = () => {
    const selectedServiceLevels = this.getSelectedServiceLevels();
    if (!selectedServiceLevels || !selectedServiceLevels.length) {
      return false;
    }

    // determine if all service levels have the same starting value for Certificate
    let  differentCertificatesSelected = false;
    const certificate = selectedServiceLevels.reduce((acc, serviceLevel) => {
      if (acc === 'sentinel') {
        return serviceLevel.certificate;
      }
      if (acc !== serviceLevel.certificate) {
        differentCertificatesSelected = true;
        return '';
      }
      return acc;
    }, 'sentinel');
    return !differentCertificatesSelected;
  }

  getSelectedServiceLevels = () => {
    const assets = this.getSelectedAssets();
    if (!assets) {
      return [];
    }
    const serviceLevels = assets.reduce((list, asset) => {
      asset.serviceLevels.forEach(serviceLevel => {
        list.push(serviceLevel);
      });
      return list;
    }, []);
    return serviceLevels;
  }

  getCertificate = (serviceLevels) => {
    const certificate = serviceLevels.reduce((acc, serviceLevel) => {
      if (acc === 'sentinel') {
        return serviceLevel.certificate;
      }
      if (acc !== serviceLevel.certificate) {
        return '';
      }
      return acc;
    }, 'sentinel');
    return certificate || '';
  }

  editCertificate = () => {
    if (!this.canEditCertificate()) {
      return;
    }
    const job = this.state.inspectionJob;
    const assets = this.getSelectedAssets();
    const header = 'Modify Certificate for ' + Utils.pluralize('asset', 's', assets.length) + ' on job # ' + job.jobNumber;
    const serviceLevels = this.getSelectedServiceLevels();
    const serviceLevelIds = serviceLevels.map(sl => sl.jobSOWServiceLevelId);
    const certificate = this.getCertificate(serviceLevels);

    co(function* () {
      yield Dialog.showDialogWaitForResult(<EditAssetCertificateDialog controller={new EditAssetCertificateController(job.jobId, job.jobNumber, certificate, serviceLevelIds, assets.length)} header={header} />);
    });
  }

  tabShow = (tabId) => {
    if (tabId === '2') {
      this.state.activeTab = 2;
      if (this.state.selectedJob) {
        this.getReadingDetailsForSerial(true);
      }
    }
    else {
      this.state.activeTab = 1;
      if (this.state.selectedJob) {
        this.loadAssetsForJob(this.state.selectedJob && this.state.selectedJob.id);
        this.configureTestButtons();
      }
    }
    this.commit();
  }

  getReadingDetailsForSerial = (unmarkDownloadToScope = false) => {
    const self = this;
    const s = self.state;
    ajax.get('gauge/getUTReadingDetailsForSerial', { jobId: s.selectedJob.id, unmarkDownload: unmarkDownloadToScope }).then(result => {
      if (result) {
        s.serialWithReadings = result.serialWithReading;
        s.serialWithoutReadings = result.serialWithoutReading;
        self.commit();
        // s.assetsMarkedDownload = result.assetsMarkedDownload;
        const checkArray = [];
        s.serialWithReadingsHeader = [];
        self.updateAssetStatus();
        s.serialWithReadings.forEach(row => {
          if (!checkArray.includes(row.jobSOWId)) {
            checkArray.push(row.jobSOWId);
            //s.serialWithReadingsHeader.push({serial: row.serial,jobSOWId: row.jobSOWId, groupName:row.groupName, groupNumber:row.groupNumber, description: row.description, collapsed:true});
            const header = {
              serial: row.serial,
              serialId: row.serialId,
              jobSOWId: row.jobSOWId,
              groupName: row.groupName,
              groupNumber: row.groupNumber,
              description: row.description,
              collapsed: true,
              downloadToScope: row.downloadToScope,
              assetStatus: row.assetStatus
            }
            s.serialWithReadingsHeader.push(new SerialReadingHeaderRowController(self, header));
          }
        });
        self.commit();
        self.getSerialWithoutReadingHeader();
        // self.getAssetMarkedDownloadHeader();        
      }
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error in loading serials' });
    });
  }

  getReadingsConfirmation = (responsedata) => {
    Dialog.showDialog(<ReadingsConfirmationDialog controller={new ReadingsConfirmationController(this.state.selectedJob && this.state.selectedJob.id, responsedata.Serials)} header='UT readings confirmation' />);
  }

  getSerialWithoutReadingHeader = () => {
    const s = this.state;
    let checkSerialArray = [];
    s.serialWithoutReadingsHeader = [];
    s.serialWithoutReadings.forEach(row => {
      if (!checkSerialArray.includes(row.jobSOWId)) {
        checkSerialArray.push(row.jobSOWId);
        // s.serialWithoutReadingsHeader.push({ serial: row.serial, serialId: row.serialId, jobSOWId: row.jobSOWId, groupName:row.groupName, groupNumber:row.groupNumber, description: row.description, downloadToScope: false });
        const header = {
          serial: row.serial,
          serialId: row.serialId,
          jobSOWId: row.jobSOWId,
          groupName: row.groupName,
          groupNumber: row.groupNumber,
          description: row.description,
          collapsed: true,
          downloadToScope: row.downloadToScope,
          assetStatus: row.assetStatus
        }
        s.serialWithoutReadingsHeader.push(new SerialReadingHeaderRowController(this, header));
      }
    });
    this.commit();
  }

  getAssetMarkedDownloadHeader = () => {
    const s = this.state;
    let downloadSerialArray = [];
    s.assetsMarkedDownloadHeader = [];
    s.assetsMarkedDownload.forEach(row => {
      if (!downloadSerialArray.includes(row.jobSOWId)) {
        downloadSerialArray.push(row.jobSOWId);
        s.assetsMarkedDownloadHeader.push({ serial: row.serial, serialId: row.serialId, jobSOWId: row.jobSOWId, groupName: row.groupName, groupNumber: row.groupNumber, description: row.description });
      }
    });
    this.commit();
  }

  updateSerialReadingData = () => {
    // var selectedSerial = controller.state.serialWithoutReadings.filter(row => row.selected);
    // if(dmsGoData.length>0){
    //   dmsGoData.forEach(data =>{
    //     selectedSerial.forEach(dailyData =>{
    //       if(data.readingId == dailyData.readingId && data.serial==dailyData.serial){
    //         dailyData.readingValue = data.currentReading
    //       }
    //     })
    //   });
    //   this.commit();
    //   co(this, function* () {
    //     try {
    //       yield ajax.post('gauge/saveSerialReadingsFromDevice', selectedSerial);
    //       notification.action('success').post('Serial readings updated successfully');
    //     }
    //     catch (err) {
    //       notification.action('error').post({msg: err.message, title: 'Error saving serial readings Data'});
    //     }
    //   });
    // this.getReadingDetailsForSerial();
    // }
  }

  toggleCollapsed = (serialHeader) => {
    serialHeader.collapsed = !serialHeader.collapsed;
    this.commit();
  }
  toggleSerialWithoutReadingSelection = (serial) => {
    serial.selected = !serial.selected;
    this.state.serialWithoutReadings.map(row => {
      if (row.serial == serial.serial) {
        row.selected = !row.selected
      }
    })
    this.commit();
  }
  selectAllAssets = () => {
    this.state.serialWithoutReadings.map(row => {
      row.selected = true;
    });
    this.state.serialWithoutReadingsHeader.map(row => {
      row.selected = true;
    });
    this.commit();
  }
  deselectAllAssets = () => {
    this.state.serialWithoutReadings.map(row => {
      row.selected = false;
    });
    this.state.serialWithoutReadingsHeader.map(row => {
      row.selected = false;
    });
    this.commit();
  }
  markAssetToDownload = () => {
    const selectedAssetsWithoutReading = this.state.serialWithoutReadingsHeader.filter(asset => asset.state.downloadToScope);
    const selectedAssetsWithReading = this.state.serialWithReadingsHeader.filter(asset => asset.state.downloadToScope);
    const WithoutReadingId = selectedAssetsWithoutReading.map(asset => asset.state.serialId);
    const WithReadingId = selectedAssetsWithReading.map(asset => asset.state.serialId);
    const parameters = {
      jobId: this.state.selectedJob.id,
      serialId: WithoutReadingId.concat(WithReadingId)
    }
    this.state.isSaveInProgress = true;
    this.commit();

    ajax.post('gauge/markAssetToDownload', parameters).then(() => {
      this.state.isSaveInProgress = false;
      this.commit();
      this.getReadingDetailsForSerial();
      notification.action('success').post('Serials marked to download successfully');
    }).catch(err => {
      this.state.isSaveInProgress = false;
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error marking Serials to download' });
    });
  }

  updateAssetStatus = () => {
    this.state.serialWithReadings.forEach(reading => {
      if ((reading.readingValue < reading.minimumDimension) || !reading.readingValue) {
        this.state.serialWithReadings.map(row => {
          if (row.jobSOWId == reading.jobSOWId && reading.readingValue && (reading.readingValue < reading.minimumDimension)) {
            row.assetStatus = AppConstants.readingBelowMinStatusText;
          }
          else if (row.jobSOWId == reading.jobSOWId && !reading.readingValue) {
            row.assetStatus = AppConstants.readingMissingStatusText;
          }
        });
      }
    });
    this.commit();
  }
  removeFromDownload = (data) => {
    const self = this;
    var jobId = self.state.selectedJob && self.state.selectedJob.id;
    this.state.isSaveInProgress = true;
    this.commit();

    ajax.get('gauge/removeAssetFromDownload', { jobId: jobId, serialId: data.serialId }).then(result => {
      if (result) {
        self.state.isSaveInProgress = false;
        self.state.serialWithoutReadings = result.serialWithoutReading;
        self.state.assetsMarkedDownload = result.assetsMarkedDownload;
        self.commit();
        self.getSerialWithoutReadingHeader();
        self.getAssetMarkedDownloadHeader();
      }
      notification.action('success').post('Serial removed from download successfully');
    }).catch(err => {
      this.state.isSaveInProgress = false;
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error removing Serial from download' });
    });
  }


  isAssetsTestNotPerformed = (tests) => {
    if (!tests || !tests.length) {
      return false;
    }
    var notPerformedTestCount = tests.filter(x => x.testResult === AppConstants.eInspectionTestResult.NotPerformed && DateTimeFormatter.formatDate(x.testDate) !== '').length;
    return notPerformedTestCount === tests.length;
  }

  notPerformedTestsCount = (tests) => {
    if (!tests || !tests.length) {
      return false;
    }
    return tests.filter(x => x.testResult === AppConstants.eInspectionTestResult.NotPerformed && DateTimeFormatter.formatDate(x.testDate) !== '').length;
  }

  isSimilarSize = (selectedAssets) => {
    var uniqueTags = [];
    selectedAssets.map(asset => {
      if (uniqueTags.indexOf(asset.size) === -1) {
        uniqueTags.push(asset.size)
      }
    });
    return uniqueTags.length === 1;
  }


  loadUpdatedAssetsForJob = (jobId, selectedAssets) => {
    this.state.activeTestName = '';
    if (this.hasJobChanged(jobId)) {
      this.state.groups = [];
      this.commit();
    }
    var parameters = {
      jobId: jobId,
      selectedAssets: selectedAssets,
    };

    if (jobId) {
      this.isLoading = true;
      this.commit();
      ajax.post('lookup/getUpdatedInspectionAssets', parameters).then(results => {
        var updatedAssets = results.assets;
        this.state.jobChanged = false;
        if (selectedAssets && selectedAssets.length) {

          updatedAssets.forEach(asset => {
            var s = this.state.inspectionJob.assets.filter(x => x.assetId == asset.assetId && x.jobSOWId == asset.jobSOWId);
            //const index= this.state.inspectionJob.assets.indexOf(s[0]);
            // asset.selected = true;
            //  this.state.inspectionJob.assets.splice(index,1,asset);
            $.extend(s[0], asset);
            this.commit();
            this.flash.emit(s.assetId);
          });
        }
        this.initializeGroups();
        this.configureTestButtons();
        this.isLoading = false;
        this.commit();

      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });
    }
  }
 
  updateAssets=()=>{
    messages.channel('inspection').action('saved').post({ jobId: this.state.inspectionJob.jobId });
  }
  displayandsavenonAPTChart = ()=>{
    const asset = this.getSelectedAsset();
    const self = this;
    Dialog.showDialog(<ManualChartUpload parentcontroller={self} controller={new ManualChartUploadController(asset,self)} header='Upload Non-APT Pressure Test Chart' />);
  }
}
export default InspectionController;