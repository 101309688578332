import BaseController from '../../lib/BaseController';

class AssetChartController extends BaseController {
  constructor(assetChart,assetNonAPTChartData,selectedasset) {
    super('asset-chart', {
      assetChart : assetChart,
      assetNonAPTChartData : assetNonAPTChartData,
      selectedAsset : selectedasset,
      filelink :  `${process.env.REACT_APP_API_SSL}://${process.env.REACT_APP_API_HOST_NAME}${process.env.REACT_APP_API_PORT ? ':' + process.env.REACT_APP_API_PORT : ''}/${process.env.REACT_APP_API_VDIR ? process.env.REACT_APP_API_VDIR + '/' : ''}${process.env.REACT_APP_API}/lookup/nonapt/getnonaptchartsforasset/`,
    });
  }
}

export default AssetChartController;
