import BaseController from '../../lib/BaseController';
import ajax from '../../services/Ajax';
import notification from '../../services/Notification';
import co from '../../lib/Co';
import Dialog from '../../components/Dialog';
import React from 'react';
class ManualChartUploadController extends BaseController {
  constructor(selectedasset,parentcontroller) {
    super('asset-chart', {     
      selectedAsset : selectedasset,
      filelink :  `${process.env.REACT_APP_API_SSL}://${process.env.REACT_APP_API_HOST_NAME}${process.env.REACT_APP_API_PORT ? ':' + process.env.REACT_APP_API_PORT : ''}/${process.env.REACT_APP_API_VDIR ? process.env.REACT_APP_API_VDIR + '/' : ''}${process.env.REACT_APP_API}/lookup/nonapt/getnonaptchartsforasset/`,
      nonAPTCharts : [],
      parentcontroller : parentcontroller
    });
    this.getChartsList(selectedasset)
  }
  getChartsList=(asset)=>{
    const {assetId , jobSOWId , jobId }  = asset;
    ajax.get('lookup/nonapt/getnonaptchartlistforasset' , {assetId : assetId , jobsowId : jobSOWId ,  jobId : jobId }).then(x=>
    {
        if(x){
            this.state.nonAPTCharts = x
            this.commit();
        }
        else{
            notification.action('error').post({ msg: "Unable to fetch data for Non APT charts", title: 'Error' });
        }
    }).catch(err=>{
        notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }
  deleteChart=(chartId)=>
  {  
    ajax.get('inspection/deletenonaptchart', {chartId :chartId}).then(results => {
      if(results)
      {
        this.getChartsList(this.state.selectedAsset);
        notification.action('success').post('File deleted successfully for selected asset');
      }
      else{
        notification.action('error').post({ msg: "Unable to delete file. Please try again", title: 'Error' });
      }
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    }); 
 
  }
  saveNonAPTCharts = (file) => {
    const name = file.file.name;
    const filedata = file.imageUrl;
    const {assetId : assetId , jobSOWId : jobSOWId , jobId : jobId} = this.state.selectedAsset;
    const parameters = {assetId  : assetId, jobSOWId : jobSOWId , jobId: jobId , FileName : name , FileData : filedata   }
    ajax.post('inspection/savenonaptchart', parameters).then((x) => { 
      if(x)
        {
          this.getChartsList(this.state.selectedAsset);
          notification.action('success').post('File saved successfully for selected asset');
        }    
      this.commit();      
      
    }).catch(err => {     
      this.commit();
      notification.action('error').post({ msg: err.message, title: 'Error while uploading file' });
    });
  }
}

export default ManualChartUploadController;
